import { ReducedUserTokenState } from 'lib/utils/parseUser';
import Mixpanel from 'mixpanel-browser';

// TODO: trim, these might not all need to be passed in

class AnalyticsService {
  private static instance: AnalyticsService;
  private _mx: typeof Mixpanel;

  constructor() {
    if (!process.env.MIXPANEL_TOKEN) throw Error('Missing mixpanel token');

    this._mx = Mixpanel;

    this._mx.init(process.env.MIXPANEL_TOKEN);

    if (process.env.NODE_ENV === 'development') {
      this._mx.set_config({ debug: true });
    }
  }

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance;
  }

  // call when needing to set user
  setUser(user: ReducedUserTokenState) {
    this._mx.register(user);
  }

  // track event
  track(
    eventName: string,
    props: Record<string, string | boolean | null | number | undefined>,
  ) {
    return this._mx.track(eventName, props, (e) => {
      if (process.env.NODE_ENV === 'development' && e) {
        // eslint-disable-next-line no-console
        console.log(`Event fired: ${eventName}`, props);
      }
    });
  }

  // call when user logs out or change in user
  flush() {
    this._mx.reset();
  }
}

export default AnalyticsService.getInstance();
