import { useEffect, useState } from 'react';

/**
 * @param {React.RefObject<HTMLElement | undefined>} ref
 * @param {IntersectionObserverInit | undefined} [options]
 * @returns {IntersectionObserverEntry | null}
 */
const useIntersection = (ref, options = {}) => {
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useState(null);
  useEffect(() => {
    if (ref.current && typeof IntersectionObserver === 'function') {
      /** @type {IntersectionObserverCallback} */
      const handler = (entries) => {
        setIntersectionObserverEntry(entries[0]);
      };
      const observer = new IntersectionObserver(handler, options);
      observer.observe(ref.current);
      return () => {
        setIntersectionObserverEntry(null);
        observer.disconnect();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, options.threshold, options.root, options.rootMargin]);
  return intersectionObserverEntry;
};
export default useIntersection;
