import useIntersection from 'lib/hooks/useIntersection';
import { asyncTrack } from 'lib/hooks/mixpanel';
import { RefObject, useCallback, useEffect, useRef } from 'react';

export type DisplayAdLocation =
  | 'Homepage'
  // assume all pathname urls are acceptable
  | string
  | '/recipes'
  // In Mixpanel production at time of writing (12m)
  | '/articles'
  | '/episode'
  | '/episodes'
  | '/equipment_reviews'
  | '/perfectly-seasonal'
  | '/podcasts'
  | '/reviews'
  | '/shows'
  | '/skills'
  | '/taste_tests'
  | '/videos'
  | '/whats-eating-dan'
  | 'bookstore'
  | 'magazine'
  | 'school membership';

export type EmailCaptureLocation =
  | 'Homepage'
  // In Mixpanel production at time of writing (12m)
  // | 'atk homepage' removed in favor of just homepage
  | 'article detail'
  | 'article detail page'
  | 'comments'
  | 'Features Landing Page'
  | 'how to detail page'
  | 'recipe browse page'
  | 'recipe detail page'
  | 'review detail page'
  | 'reviews landing page'
  | 'Thanksgiving guide';

export type EmailCaptureType =
  | 'newsletterBelt'
  | 'magazine form'
  | 'magazine'
  // In Mixpanel production at time of writing (12m)
  | 'Article'
  | 'Content Vertical'
  | 'Free Registration'
  | 'Join'
  | 'Newsletter'
  | 'newsletter'
  | 'registration';

export type DisplayAdType =
  | 'hero'
  | 'tile'
  | 'squareBelt'
  | 'shopBelt'
  | 'cookingSchoolBelt'
  | 'newsletterBelt'
  // pre ad admin
  | 'magazine form'
  // In Mixpanel production at time of writing (12m)
  | 'bookstore'
  | 'magazine'
  | 'membership'
  | 'newsletter'
  | 'school membership'
  | "The Complete America's Test Kitchen TV Show Cookbook, 2001-2021"
  | "The Complete Cook's Country Season 14 TV Show Cookbook"
  | "The Complete Cook's Country Season 4 TV"
  | 'freevee'
  | 'amazonFresh';

/** Before utils.mixpanel.formatProperties */
type DisplayAdProperties = {
  documentTitle?: string;
  documentType?: string;
  location: DisplayAdLocation;
  status: 'Accepted' | 'Presented';
  type: DisplayAdType;
  /** Some display ads need incodes (e.x. homepage cooking school ad) */
  incode?: string;
};

export type EmailCaptureProperties = {
  location: EmailCaptureLocation;
  type: EmailCaptureType;
};

export type DisplayAdArgument = Omit<DisplayAdProperties, 'status'>;

// TODO: Change api to callback ref that returns cbref to use.
/** When a user sees an ad or clicks on an ad. */
export function useTrackDisplayAdPresentedEffect(
  ref: RefObject<HTMLElement>,
  mxProps: DisplayAdArgument,
) {
  // threshold 55% just in case some ads are larger than screen size.
  //  should be enough to say they are 'in view'
  const entry = useIntersection(ref, { threshold: 0.55 });
  const called = useRef(false);

  return useEffect(() => {
    if (called.current) return;
    if (!entry?.isIntersecting) return;
    called.current = true;
    asyncTrack('DISPLAY_AD', { status: 'Presented', ...mxProps });
  }, [entry?.isIntersecting, mxProps]);
}

/** When a user submits an ad */
export function useTrackDisplayAdAcceptedCallback(mxProps: DisplayAdArgument) {
  return useCallback(() => {
    asyncTrack('DISPLAY_AD', { status: 'Accepted', ...mxProps });
  }, [mxProps]);
}

/** When a user enters their email in an email capture form (and submits). */
export function useTrackEmailCaptureCallback(mxProps: EmailCaptureProperties) {
  return useCallback(
    (email: string) => {
      asyncTrack('EMAIL_CAPTURE', {
        email,
        ...mxProps,
      });
    },
    [mxProps],
  );
}
